<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">设备管理软件</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/69.png"
        class="SonImgW"
      />
      <br />
      <br />
      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <div class="PList" style="margin-top: 10px">
        <p>
          1）
          设备数量多、种类繁杂、役龄差异大，不知道如何科学分级和分类，导致管理混乱；
        </p>
        <p>2） 总嫌维修人员不够，新人上手慢，但又不知道如何培养；</p>
        <p>3） 保养内容缺乏精细化和标准化，导致维修时间过长且效果不佳；</p>
        <p>4） 设备维护保养没有系统性，保养计划脱节；</p>
        <p>
          5）
          设备保养工作做了很多，但还是经常出故障，仍然是救火式的管理，导致影响正常生产；
        </p>
        <p>
          6） 不愿意花时间去做设备保养和维修记录，导致无法利用数据进行历史分析；
        </p>
        <p>7） 维修经验没有得到传承，重复故障率高，导致维修成本过高；</p>
        <p>8） 没有对贵重的备件使用、更换进行监管和评估，导致备件成本过大；</p>
        <p>
          9）
          备件库存高，库存记录不准确，经常性缺件，导致影响正常的设备保养和维修；
        </p>
        <p>
          10）
          设备供应商的廉政管理不到位，导致对外包维修的供应商缺乏监管，备件供应价格过高。
        </p>
      </div>

      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        建立平台，导入初始数据
        <br />
        每年提供维护
        <br />
        在线操作培训
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>